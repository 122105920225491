import React, { useState, useRef, useEffect } from 'react';
import { StyleSheet, View, Text, Clipboard, TouchableOpacity, ScrollView, TextInput, Modal,Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useTranslation } from 'react-i18next'
import { commonStyle } from '../components/style';
import { getRechargeTron, getRecharge, getWebsiteInfo,getHuobiPrice,getSymbolPrice,rechargeChannel,getUserbalance } from '../api/apis';
import SpliteLine from '../components/spliteLine'
import Toast from 'react-native-easy-toast'
import { showAlert } from '../components/showAlert';
import AsyncStorage from '@react-native-community/async-storage';
import { unitconversion } from '../components/util'
import { AntDesign } from '@expo/vector-icons';
import * as ImagePicker from 'expo-image-picker';
import ImageViewer from '../components/ImageViewer';
import Button from '../components/Button';
import { Svg, Rect } from 'react-native-svg'; 
import QRCode from 'qrcode-generator';
import QRCodeSvg from 'react-native-qrcode-svg';

const PlaceholderImageFront = require('../../assets/imgs/common/imgload.png');

const Deposit = (props) => {

    const navigation = useNavigation();

    const { i18n, t } = useTranslation();
    const toastRef = useRef(null);

    const [rechargeWayBank, setRechargeWayBank] = useState(false);
    const [amount, setAmount] = useState('0.00');
    const [name, setName] = useState('');
    const [rechargeWayBankId, setRechargeWayBankId] = useState('');
    const [curr, setCurr] = useState('');
    const [repayment, setRepayment] = useState(false);
    const [loanamount, setLoanamount] = useState('0.00'); // 已借金额
    const [coinLoanamount, setCoinLoanamount] = useState('0.00'); // 已借金额换算成币
    const [coinPrice, setCoinPrice] = useState('0.00'); // 币价
    const [reconciliationField, setReconciliationField] = useState(''); // reconciliation_field  参数 来控制虚拟币转账是是上传核验字段还是上传图片   0 按照后台提示的核验字段 1 上传转账详情的截图
    const [selectedImage, setSelectedImage] = useState(null);
    const [qrCode, setQrCode] = useState('');

    React.useLayoutEffect(() => {
        navigation.setOptions({
            title: props.route.params.title,
            headerStyle: {
                backgroundColor: commonStyle.bgColor,
                borderBottomWidth: 0
            },
            headerTitleStyle: {
            },
        });
    }, [navigation]);


    useEffect(() => {
        init()
    }, []);

    const init = async () => {

        console.log('page:Deposit')

        // console.log('参数')
        // console.log(props.route?.params?.repayment)

        //
        if(props.route?.params?.repayment){
            setRepayment(true)
        }else{
            setRepayment(false)
        }


        let loanAmount = 0.00

        await getUserbalance({type:'loanamount'}).then(res => {
            // console.log(res)
            if(res.Data?.other?.loanamount){
                loanAmount = Number(res.Data.other.loanamount)
                setLoanamount(loanAmount)
            }
                
        })


        let huobiPrice
        if((props.route.params.title).substr(0, 4) == "USDT"){
            huobiPrice = 1
        }else{
            huobiPrice = await getHuobiPrice(props.route.params.title);
            if(!huobiPrice){
                huobiPrice = await getSymbolPrice(props.route.params.title);
            }
        }
        if (huobiPrice) {
            // alert(loanAmount)
            // alert(loanAmount / Number(huobiPrice))
            setCoinLoanamount(parseFloat(((loanAmount/100) / Number(huobiPrice)).toFixed(6)))
            setCoinPrice(huobiPrice)

            if(props.route?.params?.repayment){
                setAmount(parseFloat(((loanAmount/100) / Number(huobiPrice)).toFixed(6)).toString())
                showAlert(t('tips7') + `${parseFloat(((loanAmount/100) / Number(huobiPrice)).toFixed(6))}${props.route.params.title}`);
            }
        }

        const response = await getWebsiteInfo();
        if (response.status == '1') {
            const curr = response.Data.curr
            setCurr(curr)

            setReconciliationField(response.Data.reconciliation_field)

            getRechargeTron(curr).then(response => {
                // console.log(response)
                if (response.status == 1) {

                    if (response.item.RechargeWayBank.length == 0) {
                        navigation.navigate('PaymentMethod');
                        return
                    }

                    const index = props.route.params.itemKey
    
                    const rechargeWayBank = response.item.RechargeWayBank[index]
                    setRechargeWayBankId(rechargeWayBank.id)
                  
                    setRechargeWayBank(rechargeWayBank)

                }
            }).catch(error => {
                console.log(error)
            })

        }

    }

    const handleAmountChanged = text => {
        setAmount(text);
    };

    const handleNameChanged = text => {
        setName(text);
    };

    const handleCopy = (text) => {
        Clipboard.setString(text);
        toastRef.current.show(t('success'));
    }


    const getImageTypeFromBase64 = (base64Data) => {
        const matches = base64Data.match(/^data:(.+);base64,/);
        if (matches && matches.length > 1) {
            return matches[1];
        }
        return null;
    };

    const pickImageAsync = async () => {
        const allowedTypes = ['jpeg', 'jpg', 'png'];
        let result = await ImagePicker.launchImageLibraryAsync({
            allowsEditing: true,
            quality: 1,
        });

        // console.log(result)

        if (!result.canceled) {
            const uri = result.assets[0].uri;

            // 获取文件扩展名
            let extension = '';

            if (Platform.OS != 'web') {
                extension = uri.split('.').pop().toLowerCase();
            } else {
                extension = getImageTypeFromBase64(uri);
                extension = extension.replace('image/', '')
            }

            if (!allowedTypes.includes(extension)) {
                showAlert(t('jpgpng'));
                return;
            }


            setSelectedImage(result.assets[0].uri);
            setName(result.assets[0].uri)

        } else {
            // alert("You did not select any image.");
        }
    };

    const handleConfirm = async () => {

        let websiteInfo = await AsyncStorage.getItem('websiteInfo');
        websiteInfo = JSON.parse(websiteInfo);

        if(reconciliationField == 1 && name === ''){
            toastRef.current.show(t('uploadTransferDetailsScreenshot'));
            return;
        }
        if (name === '') {
            toastRef.current.show(rechargeWayBank.reconciliation_field);
            return;
        }

        // 使用正则表达式匹配金额，要求小数点后最多两位
        const regex = /^\d+(\.\d{1,2})?$/;
        if (!regex.test(amount.toString())) {
            // toastRef.current.show(t('invalidAmount') + amount);
            // return;
        }

        const price_min = rechargeWayBank.price_min
        const price_max = rechargeWayBank.price_max

        if (Number(amount)  < Number(rechargeWayBank.price_min) || Number(amount)  > Number(rechargeWayBank.price_max)) {
            // console.log(amount)
            // toastRef.current.show(t('agentReport.6') + `(${price_min}-${price_max})`);
            // return;
        }


        let data = `{"Money":"${amount}","NickName":"${name}","id":"${rechargeWayBankId}"}`
        if(repayment){
            let title = props.route.params.title
            data = `{"Money":"${amount}","NickName":"${name}","id":"${rechargeWayBankId}","Exchangerate":"${title}:${coinPrice}:repayment"}`
        } 
        
        const params = {
            data: data,
            operating_state: 2
        }

        rechargeChannel(params).then(response => {
            console.log(response)
            if (response.status == 1) {
                showAlert(response.msg);
                setAmount('0.00');
                setName('')
                setSelectedImage(null)
            } else {
                toastRef.current.show(response.Data.msg);
                return;
            }
        }).catch(error => {
            console.log(error)
        })

        
        // getRecharge(params, 2).then(response => {
        //     if (response.status == 1) {
        //         showAlert(response.msg);
        //         setAmount('0.00');
        //         setName('')
        //     } else {
        //         toastRef.current.show(response.Data.msg);
        //         return;
        //     }
        // }).catch(error => {
        //     console.log(error)
        // })
    }

    return (
        <View style={styles.container}>

        <ScrollView
            showsVerticalScrollIndicator={false}
            showsHorizontalScrollIndicator={false}
        >

            <View style={{justifyContent:'center',alignItems:'center'}}>

            {rechargeWayBank && <View style={styles.form}>
                {/* <View>
                    <Text style={{ color: '#fff', fontSize: 20, textAlign: 'center' }}>{rechargeWayBank.bank}</Text>
                </View> */}
                {/* <View>
                    <Text style={styles.label}>{t('payee')}</Text>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <TextInput
                            style={styles.input}
                            editable={false}
                            underlineColorAndroid="transparent"
                            placeholderTextColor={commonStyle.green}
                            value={rechargeWayBank.payee}
                        />
                        <TouchableOpacity style={styles.copyIconContainer} onPress={() => handleCopy(rechargeWayBank.payee)}>
                            <AntDesign name="copy1" size={20} color="#828B9F" />
                        </TouchableOpacity>
                    </View>

                    <SpliteLine />
                </View> */}


                <View style={{alignItems:'center'}}>
                    <View style={{ padding: 5 }}>
                        <QRCodeSvg
                            value={rechargeWayBank.collectionaccount}
                            size={120}
                            color="black"
                            backgroundColor="white"
                          
                        />

                        <Svg width={0} height={0}>
                            {/* 在 SVG 中绘制二维码 */}
                            {qrCode && <Rect width={200} height={200} fill={`url(${qrCode})`} />}
                        </Svg>

                    </View>
                   
                </View>

                <View>
                    <Text style={styles.label}>{t('accountNumber')}</Text>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <TextInput
                            style={[styles.input,{width:'100%',height:40}]}
                            editable={false}
                            multiline={true}
                            numberOfLines={5}
                            underlineColorAndroid="transparent"
                            placeholderTextColor={commonStyle.green}
                            value={rechargeWayBank.collectionaccount}
                     
                        />
                        <TouchableOpacity style={styles.copyIconContainer} onPress={() => handleCopy(rechargeWayBank.collectionaccount)}>
                            <AntDesign name="copy1" size={20} color="#828B9F" />
                        </TouchableOpacity>
                    </View>

                    <SpliteLine />
                </View>
                <View>
                    <Text style={styles.label}>{t('bankBranch')}</Text>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <TextInput
                            style={styles.input}
                            editable={false}
                            underlineColorAndroid="transparent"
                            placeholderTextColor={commonStyle.green}
                            value={rechargeWayBank.branchname}
                        />
                        <TouchableOpacity style={styles.copyIconContainer} onPress={() => handleCopy(rechargeWayBank.branchname)}>
                            <AntDesign name="copy1" size={20} color="#828B9F" />
                        </TouchableOpacity>
                    </View>
                    <SpliteLine />
                </View>
                <View>
                    <Text style={styles.label}>{t('recharge')}{t('amount')}</Text>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <TextInput
                            style={[styles.input,{flex:1}]}
                            underlineColorAndroid="transparent"
                            placeholderTextColor={commonStyle.green}
                            onChangeText={handleAmountChanged}
                            value={amount}
                        />
                        <TouchableOpacity style={styles.copyIconContainer} onPress={() => handleCopy(amount)}>
                            <AntDesign name="copy1" size={20} color="#828B9F" />
                        </TouchableOpacity>
                    </View>
                    <SpliteLine />
                </View>

    
                {reconciliationField == 1  ? 
                <View style={{alignItems:'center',marginTop:5}}>
                {selectedImage && <ImageViewer placeholderImageSource={PlaceholderImageFront} selectedImage={selectedImage} />}
                <View style={{marginVertical:5}}>
                    <Button theme="primary" label={t('uploadTransferDetailsScreenshot')} onPress={() => pickImageAsync()} />
                </View>

                </View>
                : 
                <View>
                    <Text style={styles.label}>{rechargeWayBank.reconciliation_field}</Text>
                    <TextInput
                        style={styles.input}
                        underlineColorAndroid="transparent"
                        placeholderTextColor={commonStyle.green}
                        onChangeText={handleNameChanged}
                        placeholder={rechargeWayBank.reconciliation_field}
                        value={name}
                    />
                    <SpliteLine />
                </View>
                }

       

                <TouchableOpacity style={styles.button} onPress={handleConfirm}>
                    <Text style={styles.buttonText}>{t('confirm')}</Text>
                </TouchableOpacity>

                <View style={{ marginTop: 20 }}>
                    {repayment && <Text style={{color: 'gray',marginBottom:5}}>{t('borrowedAmount')}: {unitconversion(loanamount)} USDT = {coinLoanamount}{props.route.params.title}</Text> }
                    <Text style={{ color: 'gray',marginBottom:10 }}>1{props.route.params.title} = {coinPrice}USDT</Text>
                    <Text style={{ color: 'gray' }}>{t('tips')}</Text>
                    <Text style={{ color: 'gray' }}>{t('rechargeTip.4')}</Text>
                </View>

            </View>}
            </View>
            </ScrollView>

            <Toast
                ref={toastRef}
                style={{ backgroundColor: 'gray' }}
                position='top'
                positionValue={200}
                // fadeInDuration={750}
                // fadeOutDuration={1000}
                // opacity={0.8}
                textStyle={{ color: '#fff' }}
            />
        </View>

    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,  // 主轴上尺寸比例
        backgroundColor: commonStyle.bgColor,
        // alignItems: 'center', // 交叉轴对齐方式
        // justifyContent: 'center',//主轴对齐方式
        // flexDirection:"column" //主轴方向
    },
    form: {
        width: '80%',
    },
    label: {
        color: '#fff',
        fontSize: 16,
        marginTop: 10,
    },
    input: {
        color: commonStyle.green,
        marginBottom: 5,
        marginTop: 5,
        height: 30,
        borderWidth: 0,
        borderBottomColor: 'transparent',
    },
    inviteCodeInput: {
        flex: 2,
        width: 230,
    },
    inputContainer: {
        flexDirection: 'row',
    },
    requiredText: {
        flex: 1,
        color: '#fff',
        lineHeight: 45,
        textAlign: 'right',
        fontSize: 18,
    },
    button: {
        backgroundColor: commonStyle.green,
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
        marginTop: 10,
        marginBottom: 10,
        borderRadius: 5,
    },
    buttonText: {
        color: '#fff',
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center',
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
        maxWidth: 500,
        width: '100%',
        marginHorizontal: 'auto'
        // backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalContent: {
        backgroundColor: '#1B1D29',
        // padding: 20,
        borderRadius: 10,
        width: '100%',
        height: '80%'
    },
    modelDown: {
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 'auto',
        marginBottom: 15,
        marginTop: 20
    },
    modalText: {
        color: '#8C99B3',
        textAlign: 'center',
        height: 40,
        fontSize: 16,
        lineHeight: 35
    },
    modalTextSelected: {
        backgroundColor: '#0F1017',
        opacity: 0.45
    },
    iconText: {
        flexDirection: 'row',
        alignItems: 'center',
        // marginBottom: 10,
        color: '#828B9F',
    },
    goArrow: {
        fontSize: 20,
        // fontWeight: 'bold',
    },
    card: {
        backgroundColor: '#FAAE27',
        borderRadius: 5,
        justifyContent: 'center',
        // paddingVertical: 15,
        // paddingHorizontal: 10,
        marginTop: 10,
    },
});

export default Deposit;