import React, { useState, useRef, useEffect } from 'react';
import { StyleSheet, View, Text, TextInput, TouchableOpacity, Modal, ScrollView,Image,ActivityIndicator } from 'react-native';
import { useTranslation } from 'react-i18next';
import SpliteLine from '../components/spliteLine'
import { commonStyle } from '../components/style'
import Toast, { DURATION } from 'react-native-easy-toast'
import { useNavigation,useFocusEffect } from '@react-navigation/native';
import { AntDesign, Entypo } from '@expo/vector-icons';
import * as ImagePicker from 'expo-image-picker';
import { getUserbalance, getwithdrawDetermine } from '../api/apis';
import { unitconversion } from '../components/util'

// getUserbalance  type：all 所有的余额  loanratio借款比例	loanamount借款金额 	bettingratio投注比例  其他的对应的币种资产
// getwithdrawDetermine  channel参数 ：withdraw 一般提现  earnWallet 提现到钱包  loan借款  exchange兑换

const App = () => {
    const { t } = useTranslation();
    const toastRef = useRef(null);
    const [initLoading, setInitLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [amount, setAmount] = useState('0.00');  // 输入的金额
    const [userbalance, setUserbalance] = useState('0.00');  // 用户余额
    const [borrowable, setBorrowable] = useState('0.00'); // 可借金额
    const [loanamount, setLoanamount] = useState('0.00'); // 已借金额
    const [financialPassword, setFinancialPassword] = useState(''); // 资金密码

    const [repayment, setRepayment] = useState(''); //是否支持余额还款 1支持 2是不支持
    

    const navigation = useNavigation();

    useEffect(() => {
        
    }, []);

    useFocusEffect(
        React.useCallback(() => {
            init()
        }, [])
    );


    const init = async () => {

        let balance = 0.00;
         await getUserbalance().then(res => {
            // console.log('余额',res.Data.BackData)
            balance = Number(res.Data.BackData)
            setUserbalance(balance)
        })

        await getUserbalance({type:'loanamount'}).then(res => {
            // console.log(res)
            setLoanamount(Number(res.Data?.other?.loanamount))

            setRepayment(res.Data?.repayment)
        })

        await getUserbalance({type:'loanratio'}).then(res => {

            let loanratio = Number(res.Data?.other?.loanratio)
            // console.log('借款比例',res.Data.other[0].loanratio)

            // console.log(balance,loanratio)
            // console.log('可借金额',  (balance * (loanratio / 100)).toFixed(2) )

            setBorrowable(Math.floor(((balance - loanamount)  * (loanratio / 100)) - loanamount))
        })

        setInitLoading(false)
    }

    const handleamountChanged = input => {
        const regex = /^(?!0\d|$)(\d+(\.\d{0,2})?)?$/;
        if (regex.test(input) && parseFloat(input) >= 0 || input === '') {
            // setInterest( (input *dailyInterestRate / 100).toFixed(2))
            setAmount(input);
        }
    };

    const handleFinancialPasswordChanged = input => {
        setFinancialPassword(input)
    };


    const goToRepaymentScreen = () => {

        getUserbalance({type:'loanamount'}).then(res => {
            if(res.Data?.repayment == 1){
                navigation.navigate('Repayment');
                return
            }
            if(res.Data?.repayment == 2){
                navigation.navigate('RechargeChannel',{repayment:true});
                return
            }
        })
       
    }


    const handleConfirm = async () => {

        if (amount <= 0) {
            toastRef.current.show(t('invalidAmount'));
            return;
        }

        const regex = /^\d+(\.\d{1,2})?$/;
        if (!regex.test(amount)) {
            toastRef.current.show(t('invalidAmount'));
            return;
        }

        if(Number(amount) > Number(borrowable)){
            toastRef.current.show(t('invalidAmount'));
            return;
        }

        if(financialPassword == ''){
            toastRef.current.show(t('pleaseEnterFinancialPassword'));
            return;
        }

        setIsLoading(true);

        const params = {
            Withdrawal_Amount: amount,
            channel:'loan',
            safe:financialPassword
        }

        
        try {
            const response = await getwithdrawDetermine(JSON.stringify(params));
            setIsLoading(false);
            // console.log(response)
            if (response.status == '1') {
                toastRef.current.show(response.msg);
                setAmount('0.00');
                setFinancialPassword('');
                init()
            }else{
                toastRef.current.show(response.Data.msg);
            }
        } catch (error) {
            // console.log(error)
            setIsLoading(false);
            toastRef.current.show(t('fail'));
        }

    };

    return (

        <View style={styles.container}>

        {!initLoading && 
            <ScrollView
                showsVerticalScrollIndicator={false}
                showsHorizontalScrollIndicator={false}
                style={{ width: '95%', marginVertical: 20 }}
            >
                <View style={styles.form}>

                    {borrowable > 0 && 
                    <View>

                        <Text style={styles.label}>{t('loan') + t('amount')}</Text><View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <TextInput
                                    style={[
                                        styles.input,
                                    ]}
                                    onChangeText={handleamountChanged}
                                    underlineColorAndroid="transparent"
                                    placeholderTextColor={commonStyle.green}
                                    value={amount} />
                                <TouchableOpacity onPress={() => setAmount((borrowable/100).toFixed(2))}>
                                    <Text style={{ color: '#fff' }}>{t('all')}</Text>
                                </TouchableOpacity>
                            </View>
                 
                    
                        <SpliteLine />

                        <Text style={styles.label}>{t('financialPassword')}</Text>
                        <TextInput
                            style={[
                                styles.input,
                            ]}
                            onChangeText={handleFinancialPasswordChanged}
                            underlineColorAndroid="transparent"
                            placeholder={t('pleaseEnterFinancialPassword')}
                            placeholderTextColor={commonStyle.green}
                            value={financialPassword}
                        />
                        <SpliteLine />

                    </View>
                    }

                        
                    {borrowable > 0 && 
                    <TouchableOpacity style={styles.button} onPress={handleConfirm} disabled={isLoading}>
                        <Text style={styles.buttonText}>{t('loan')}</Text>
                    </TouchableOpacity>
                    }
           

                    {loanamount > 0 && 
                    <TouchableOpacity style={styles.button} onPress={goToRepaymentScreen}>
                        <Text style={styles.buttonText}>{t('repayment')}</Text>
                    </TouchableOpacity>
                    }


                    <View>
                        <Text style={{color: '#707070'}}>{t('accountBalance')}: {unitconversion(userbalance)}</Text>
                        <Text style={{color: '#707070'}}>{t('availableAmount')}: {unitconversion(borrowable)}</Text>
                        <Text style={{color: '#707070'}}>{t('borrowedAmount')}: {unitconversion(loanamount)}</Text>
                    </View>


                </View>

            </ScrollView>
            }

            <Toast
                ref={toastRef}
                style={{ backgroundColor: 'gray' }}
                position='top'
                positionValue={200}
                // fadeInDuration={750}
                // fadeOutDuration={1000}
                // opacity={0.8}
                textStyle={{ color: '#fff' }}
            />

            {initLoading && 
                <ActivityIndicator size="large" color={commonStyle.green}  />
            }


        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,  // 主轴上尺寸比例
        backgroundColor: commonStyle.bgColor,
        alignItems: 'center', // 交叉轴对齐方式
        justifyContent: 'center',//主轴对齐方式
        // flexDirection:"column" //主轴方向
    },
    form: {
        // width: '80%',
        paddingHorizontal: 10,
        justifyContent: 'center',

    },
    label: {
        color: '#fff',
        fontSize: 16,
        marginTop: 10,
    },
    input: {
        color: commonStyle.green,
        marginBottom: 4,
        marginTop: 2,
        height: 25,
        borderWidth: 0,
        borderBottomColor: 'transparent',
        flex:1
    },
    inviteCodeInput: {
        flex: 2,
        width: 230,
    },
    inputContainer: {
        flexDirection: 'row',
    },
    requiredText: {
        flex: 1,
        color: '#fff',
        lineHeight: 45,
        textAlign: 'right',
        fontSize: 18,
    },
    button: {
        backgroundColor: commonStyle.green,
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
        marginTop: 10,
        marginBottom: 10,
        borderRadius: 5,
    },
    buttonText: {
        color: '#fff',
        fontWeight: 'bold',
        width: '100%',
        textAlign: 'center',
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
        maxWidth: 500,
        width: '100%',
        marginHorizontal: 'auto'
        // backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalContent: {
        backgroundColor: '#1B1D29',
        // padding: 20,
        borderRadius: 10,
        width: '100%',
        height: '90%'
    },
    modelDown: {
        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: 'auto',
        marginBottom: 15,
        marginTop: 20
    },
    modalText: {
        color: '#fff',
        textAlign: 'center',
        height: 40,
        fontSize: 16,
        lineHeight: 35
    },
    modalTextSelected: {
        backgroundColor: '#0F1017',
        opacity: 0.45
    },
    iconText: {
        flexDirection: 'row',
        alignItems: 'center',
        // marginBottom: 10,
        color: '#828B9F',
    },
    goArrow: {
        fontSize: 20,
        // fontWeight: 'bold',
    },
    card: {
        // backgroundColor: '#FAAE27',
        borderRadius: 5,
        justifyContent: 'center',
        // paddingVertical: 15,
        // paddingHorizontal: 10,
        marginTop: 10,
    },
    image:{
        width:80,
        height:80
    }
});

export default App;